import Vue from 'vue'
import App from './App.vue'

// import "./assets/css/bulma.min.css"
// import "./assets/css/all.min.css"
// import "./assets/css/fonts.css"
import "../../public/main.css"
import "../../public/bulma.min.css"

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
